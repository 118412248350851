(function (window) {
    window.__env = window.__env || {};

    window.__env.apiUrl = '#{apiUrl}#';

    window.__env.AzureADSettings = {
        endpoints: {},
        instance: 'https://login.microsoftonline.com/',
        tenant: '#{tenant}#',
        clientId: '#{clientId}#',
        redirectUri: '#{redirectUri}#',
        anonymousEndpoints: []
    };

    window.__env.AzureADSettings.endpoints[window.__env.apiUrl] = '#{vhimsApiResourceId}#';

    //These must me here and cannot be added to the anonymousEndpoints declaration. Otherwise IE fails to load the website
    window.__env.AzureADSettings.anonymousEndpoints.push(__env.apiUrl + "/HealthCheck/Check");
    window.__env.AzureADSettings.anonymousEndpoints.push("images/");

    window.__env.layoutVersion = '#{layoutVersion}#';
    window.__env.environmentName = '#{environmentName}#';
}(this));